/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

html,
body {
  height: 100%;
  background-color: #f7f8f9;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'Inter var', Roboto, 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  height: 30px;
}

.single-page-main-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
